import React from 'react'
import InstagramFeed from './InstagramFeed'
import './Footer.css'

export default () => (
  <div>
    {/*<h2 className="taCenter">*/}
    {/*  Instagram{' '}*/}
    {/*  <a href="https://www.instagram.com/xxx/">@xxx</a>*/}
    {/*</h2>*/}
    <br />
    {/*<InstagramFeed count="8" />*/}
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} {' '}
          <a href="https://softax.com.br/"> Globalyze.Work</a>.
        </span>
      </div>
    </footer>
  </div>
)
